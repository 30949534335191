import { ChartjsTooltip } from '../../../../components';
import {
  getSubjectColor,
  getSelectedLocationName,
  getSelectedSubjectName,
} from '../../../../actions';
import i18n from '../../../../i18n';

const getDataForTooltip = (selectedSubject, state) => {
  const body = {};
  const location = getSelectedLocationName(state, 'g1');

  body[location] = {
    abr: selectedSubject,
    label: `${getSelectedSubjectName(state, selectedSubject)}: ${location}`, // eslint-disable-line i18next/no-literal-string
  };

  return {
    footer: [i18n.t('African average'), i18n.t('African minimum'), i18n.t('African maximum')],
    body,
  };
};

export const getChartTitle = () => {};

export const getChartConfig = (state) => {
  const max = {};
  const min = {};
  const avg = {};

  const tooltipData = getDataForTooltip(state.selectedSubject, state);

  Object.keys(state.measureData).forEach((yr) => {
    const thisYear = [ ...Object.values(state.measureData[yr]) ];
    avg[yr] = Number(
      thisYear
        .map((e) => parseFloat(e.v, 10))
        .reduce((a, b) => a + b) / thisYear.length,
    ).toFixed(1);
    min[yr] = Number(thisYear.pop().v).toFixed(1);
    max[yr] = Number(thisYear.shift().v).toFixed(1);
  });

  const rangeData = {
    labels: Object.keys(avg),
    datasets: [
      {
        label: getSelectedLocationName(state, 'g1'),
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        data: Object.values(avg),
        pointStyle: 'none',
        fill: {
          target: '-1',
        },
        tooltip: {
          order: 16,
        },
      },
      {
        label: i18n.t('African average'),
        backgroundColor: getSubjectColor({
          state,
          subject: state.selectedSubject,
        }),
        borderColor: getSubjectColor({ state, subject: state.selectedSubject }),
        data: Object.values(avg),
        pointStyle: 'none',
        tooltip: {
          order: 15,
        },
      },
      {
        label: i18n.t('African minimum'),
        backgroundColor: `${getSubjectColor({
          state,
          subject: state.selectedSubject,
        })}44`,
        borderColor: 'transparent',
        data: Object.values(min),
        pointStyle: 'none',
        tooltip: {
          order: 12,
        },
      },
      {
        label: i18n.t('African maximum'),
        backgroundColor: `${getSubjectColor({
          state,
          subject: state.selectedSubject,
        })}44`,
        borderColor: 'transparent',
        data: Object.values(max),
        pointStyle: 'none',
        fill: {
          target: '-1',
        },
        tooltip: {
          order: 13,
        },
      },
    ],
  };

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = () => {
        fitValue.bind(chart.legend)();
        this.top += 50;
        return this.top;
      };
    },
  };
  const rangeTitle = getSelectedSubjectName(state, state.selectedSubject);
  const formattedRangeTextTitle = window.innerWidth < 480
    ? [rangeTitle, i18n.t('Range of scores')]
    : `${rangeTitle} - ${i18n.t("Range of scores")}`;// eslint-disable-line i18next/no-literal-string

  return {
    type: 'line',
    data: rangeData,
    options: {
      interaction: {
        mode: 'index',
        intersect: false,
      },
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 30,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90,
            labelOffset: -8,
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (e) => e.toFixed(1),
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: formattedRangeTextTitle,
          font: {
            size: 16,
            family: 'museo-sans',
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 30,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            filter: (item) => item.text !== getSelectedLocationName(state, 'g1')
              && item.text !== i18n.t('African maximum'),
            usePointStyle: true,
            generateLabels(chart) {
              // this method is a copy + edit of the one from core chartjs.
              // chartjs updates may need it copy/pasted/rededited.
              // reference https://github.com/chartjs/Chart.js/blob/v3.3.2/src/plugins/plugin.legend.js#L651
              // just added the overrides on if / if/else to allow variation per dataset
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              return chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                // const borderWidth = toPadding(style.borderWidth);
                if (meta.index === 0) {
                  // style.borderDash = [3, 3];
                }
                if (meta.index === 2) {
                  style.pointStyle = 'circle';
                } else {
                  style.pointStyle = 'line';
                }

                return {
                  text:
                    meta.index === 2
                      ? i18n.t('Range of scores')
                      : datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0, // TODO: v4, default to style.borderRadius

                  // Below is extra data used for toggling the datasets
                  datasetIndex: meta.index,
                };
              }, this);
            },
            padding: 28,
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ChartjsTooltip({
              state,
              context,
              tooltipData,
              activeDatasets: [],
            });
          },
        },
      },
    },
    plugins: [legendMargin],
  };
};
