import '../../../../../legacyjs/custom/vars';
import { ChartjsTooltip } from '../../../../components';
import {
  getDatasetForMultipleLocations,
  getDatasetForMultipleSubjects,
  getSelectedLocationName,
  getSelectedLocations,
  getSelectedSubjectName,
  getSelectedSubjects,
  getSelectedYears,
  getSubjectLevel,
  getSubjectTitle,
  getLocationTitle,
  getUrlQueryParams,
  getGroupCodeFromName,
  getGroupAveragesForSubjectForGraph,
  getLevelForMeasure,
  getGroupMaximumsForSubject,
  getGroupMinimumsForSubject,
  setUrlQueryParam,
  removeUrlQueryParam,
  isCitvoiceMeasure,
} from '../../../../actions';
import {useTransition} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../../../../i18n";

const isMultipleSubjectSelected = (selectedSubject) => selectedSubject.length > 1;

const getDataForMultipleLocations = ({
  state, subject, locations, years,
}) => getDatasetForMultipleLocations(state, subject, locations, years);

const getDataForMultipleSubjects = ({
  state, subjects, location, years,
}) => getDatasetForMultipleSubjects(state, subjects, location, years);

const getAvarageValuesForSelectedYears = (data, selectedYears) => {
  const averageValues = [];
  data.values.forEach((value) => (selectedYears.includes(value.x) ? averageValues.push(value.y) : null));
  return averageValues;
};

export const getChartTitle = (state) => {
  const chartTitle = i18n.t("{{subject}} for {{location}}", {subject: getSubjectTitle(state), location:getLocationTitle(state) });

  return chartTitle;
};

export const chartDataIterator = (
  state,
  values,
  name,
  linestyle,
  fillColor,
  subjectColor,
  area,
  strokewidth,
  fillOpacity,
  subjcode,
) => {
  const dict = {};
  dict.area = area;
  dict.classed = linestyle;
  dict.classed
    += subjcode && isCitvoiceMeasure(state, subjcode) ? ' citvoice' : ' regular';
  dict.color = fillColor;
  dict.subjcolor = subjectColor;

  dict.key = name;
  dict.lvl = subjcode ? getLevelForMeasure(subjcode) : 0;
  dict.strokeWidth = strokewidth;
  dict.fillOpacity = subjcode && isCitvoiceMeasure(state, subjcode) ? 0 : fillOpacity;
  dict.subjcode = subjcode;
  dict.pointBorderColor = subjcode && isCitvoiceMeasure(state, subjcode) ? subjectColor : '#999999';
  dict.pointFillColor = subjectColor;

  const val = [];
  Object.keys(values).forEach((obj) => {
    const pos = {};
    const k = parseInt(Object.keys(values[obj])[0]);
    pos.x = parseInt(Object.keys(values[obj])[0]);
    pos.y = values[obj][k];
    pos.subjcode = subjcode;
    pos.subjcolor = subjectColor;
    pos.lvl = getLevelForMeasure(state, subjcode);
    pos.shape = 'outlinecircle';
    if (pos.y === 'n/a') {
      return;
    }
    val.push(pos);
  });

  dict.values = val;
  return dict;
};

const getAfricanAvarageStats = (state, subject, selectedYears, filter) => {
  const filters = filter ? filter.split(',') : [];

  const avgData = chartDataIterator(
    state,
    getGroupAveragesForSubjectForGraph(
      state,
      getGroupCodeFromName(state, 'Africa'),
      subject[0],
    ),
    i18n.t("African average"),
    'dashed',
    '#8e8e8e',
    false,
    false,
    global.standard_width,
    1,
  );
  const maxData = chartDataIterator(
    state,
    getGroupMaximumsForSubject(state, getGroupCodeFromName(state, 'Africa')),
    i18n.t("African maximum"),
    'solid',
    '#d9e0eb',
    false,
    true,
    1,
    0.5,
  );
  const minData = chartDataIterator(
    state,
    getGroupMinimumsForSubject(state, getGroupCodeFromName(state, 'Africa')),
    i18n.t("African minimum"),
    'solid',
    'white',
    false,
    true,
    1,
    0.5,
  );

  return [
    {
      label: i18n.t('African average'),
      backgroundColor: '#9C9C9C',
      borderColor: '#9C9C9C',
      data: getAvarageValuesForSelectedYears(avgData, selectedYears),
      hidden: filters.includes('africanaverage'),
      borderDash: [3, 3],
      pointStyle: 'none',
      tooltip: {
        order: 4,
      },
      legend: {
        show: true,
        pointStyle: 'line',
        borderDash: [3, 3],
      },
    },
    {
      label: i18n.t('African minimum'),
      backgroundColor: 'rgba(226, 229, 240, 0.5)',
      borderColor: 'rgba(226, 229, 235, 1)',
      hidden: filters.includes('rangeofscores'),
      data: getAvarageValuesForSelectedYears(minData, selectedYears),
      pointStyle: 'none',
      tooltip: {
        order: 2,
        divider: true,
      },
      toggleGroup: 'a',
    },
    {
      label: i18n.t('African maximum'),
      backgroundColor: 'rgba(226, 229, 240, 0.5)',
      borderColor: 'rgba(226, 229, 235, 1)',
      hidden: filters.includes('rangeofscores'),
      data: getAvarageValuesForSelectedYears(maxData, selectedYears),
      pointStyle: 'circle',
      fill: {
        target: '-1',
      },
      tooltip: {
        order: 3,
      },
      legend: {
        show: true,
        label: i18n.t('Range of scores'),
      },
      toggleGroup: 'a',
    },
  ];
};

const getDataForTooltip = (state, subjects, locations) => {
  const body = {};

  if (isMultipleSubjectSelected(subjects)) {
    const location = getSelectedLocationName(state, locations[0]);
    subjects.forEach((subject) => {
      const subjectLevel = getSubjectLevel(state, subject);
      body[getSelectedSubjectName(state, subject)] = {
        abr: subject,
        label: `${// eslint-disable-line i18next/no-literal-string
          subjectLevel === 1
            ? getSelectedSubjectName(state, subject).toUpperCase()
            : getSelectedSubjectName(state, subject)
        }: ${location}`,
      };
    });
  } else {
    locations.forEach((location) => {
      const subjectLevel = getSubjectLevel(state, subjects[0]);
      body[getSelectedLocationName(state, location)] = {
        abr: subjects[0],
        label: `${// eslint-disable-line i18next/no-literal-string
          subjectLevel === 1
            ? getSelectedSubjectName(state, subjects[0]).toUpperCase()
            : getSelectedSubjectName(state, subjects[0])
        }: ${getSelectedLocationName(state, location)}`,
      };
    });
  }

  return {
    footer: [i18n.t('African average'), i18n.t('African minimum'), i18n.t('African maximum')],
    body,
  };
};

const getDatasetsForChart = (
  state,
  subjects,
  locations,
  selectedYears,
  indexedYml,
) => {
  if (isMultipleSubjectSelected(subjects)) {
    return getDataForMultipleSubjects({
      state,
      subjects,
      location: locations[0],
      years: selectedYears,
    });
  }
  return getDataForMultipleLocations({
    state,
    subject: subjects[0],
    locations,
    years: selectedYears,
    indexedYml,
  });
};

export default (state) => {
  const { indexedYml } = state;
  const subjects = getSelectedSubjects(state);
  const locations = getSelectedLocations(state);
  const selectedYears = getSelectedYears('range1from', 'range1to');

  const chartData = getDatasetsForChart(
    state,
    subjects,
    locations,
    selectedYears,
    indexedYml,
  );
  let activeDatasets = [];
  const tooltipData = getDataForTooltip(state, subjects, locations);
  const { filter } = getUrlQueryParams();

  const rangeData = {
    labels: selectedYears,
    maintainAspectRatio: false,
    datasets: [...chartData],
  };

  if (subjects.length === 1) {
    rangeData.datasets.push(
      ...getAfricanAvarageStats(state, subjects, selectedYears, filter),
    );
  }

  getChartTitle(state);

  const annotationLine = {
    id: 'annotationLine',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const { ctx } = chart;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.moveTo(activePoint.element.x, chart.chartArea.top);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgb(200,200,200)';
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  return {
    type: 'line',
    data: rangeData,
    plugins: [annotationLine],
    options: {
      onClick: (e, _, myChart) => {
        const activePoints = myChart.getElementsAtEventForMode(
          e,
          'nearest',
          {
            intersect: false,
          },
          false,
        );

        if (activePoints.length === 0) {
          return;
        }

        const [{ datasetIndex }] = activePoints;

        if (
          rangeData.datasets[datasetIndex].borderColor === 'rgb(140,140,140)'
        ) {
          activeDatasets.push(rangeData.datasets[datasetIndex].label);
          rangeData.datasets[datasetIndex].borderColor = rangeData.datasets[datasetIndex].subjectColor;
        } else {
          rangeData.datasets[datasetIndex].borderColor = 'rgb(140,140,140)';
          activeDatasets = activeDatasets.filter(
            (item) => item !== rangeData.datasets[datasetIndex].label,
          );
        }
        myChart.update();
      },
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 10,
          right: 10,
          bottom: 35,
          top: 30,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            color: 'black',
            display: true,
            text: i18n.t('TIME (YEARS)'),
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          ticks: {
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
            color: 'black',
            autoSkip: false,
            maxRotation: 90,
            minRotation: 0,
            labelOffset: 0,
          },
        },
        y: {
          title: {
            display: true,
            text: i18n.t('Score').toUpperCase(),
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          display: true,
          min: 0,
          max: 100,
          ticks: {
            color: 'black',
            stepSize: 10,
            callback: (e) => e.toFixed(1),
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
              color: 'black',
            },
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        title: {
          display: false,
          text: getChartTitle(state),
          font: {
            size: 16,
            family: 'museo-sans',
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 35,
          },
        },
        legend: {
          position: 'bottom',
          onClick: (evt, legendItem, legend) => {
            const ci = legend.chart;
            const visible = ci.isDatasetVisible(legendItem.datasetIndex);
            if (
              legend.chart.data.datasets[legendItem.datasetIndex].toggleGroup
            ) {
              const group = legend.chart.data.datasets[legendItem.datasetIndex].toggleGroup;
              legend.chart.data.datasets.forEach((dataset, index) => {
                if (dataset.toggleGroup === group) {
                  // visible ? ci.hide(index) : ci.show(index);
                  // dataset.hidden = visible;
                  ci.setDatasetVisibility(index, !visible);
                }
              });
              legend.chart.update();
            } else {
              visible
                ? ci.hide(legendItem.datasetIndex)
                : ci.show(legendItem.datasetIndex);
            }
            const disabledLegendItems = legend.legendItems.filter(
              (item) => item.hidden,
            );

            if (disabledLegendItems.length > 0) {
              const urlQueryParam = disabledLegendItems
                .map((itemToDisable) => itemToDisable.text.toLowerCase().split(' ').join(''))
                .join(',');

              setUrlQueryParam({ name: 'filter', value: urlQueryParam });
            } else {
              removeUrlQueryParam({ name: 'filter' });
            }
            legendItem.hidden = !visible;
          },
          labels: {
            filter: (item, chart) => {
              if (subjects.length > 1) {
                return false;
              }
              return chart.datasets[item.datasetIndex].legend?.show ?? false;
            },
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
              color: 'black',
            },
            pointStyle: 'line',
            usePointStyle: true,
            generateLabels(chart) {
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              return chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };

                return {
                  text:
                    datasets[meta.index].legend?.label
                    ?? datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash:
                    datasets[meta.index].legend?.borderDash ?? style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle:
                    datasets[meta.index].legend?.pointStyle ?? style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0,
                  datasetIndex: meta.index,
                };
              }, this);
            },
            padding: 28,
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => ChartjsTooltip({
            state,
            context,
            tooltipData,
            activeDatasets,
          }),
        },
      },
    },
  };
};
