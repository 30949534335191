import '../../../../../legacyjs/custom/vars';
import { ScatterChartTooltip } from '../../../../components';
import {
  getSelectedLocations,
  getSelectedSubjects,
  getSelectedYears,
  getSubjectTitle,
  getLocationTitle,
  generateObjectForGraph,
  getSelectedSubjectName,
} from '../../../../actions';
import i18n from "../../../../i18n";

export const getChartTitle = (state) => {
  return i18n.t("{{subject}} for {{location}}", { subject: getSubjectTitle(state), location: (state)})
};

export default (state, urlParams) => {
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedYears2 = getSelectedYears('range2from', 'range2to');
  const selectedYearsRange1 = getSelectedYears('range1from', 'range1to'); // Copy to the specific object for this display
  const selectedYearsRange2 = getSelectedYears('range2from', 'range2to'); // Copy to the specific object for this display
  const selectedLocations = getSelectedLocations(state);
  const selectedSubjects = getSelectedSubjects(state);
  const collection = [];
  const fullContextCollection = [];
  const notApplicable = 'n/a';

  selectedLocations.forEach((location) => {
    const newItem = generateObjectForGraph({
      state,
      location,
      subject: selectedSubjects[0],
      selectedYearsRange1,
      selectedYearsRange2,
    });

    if (
      newItem.data[0].x !== notApplicable
      && newItem.data[0].y !== notApplicable
    ) {
      collection.push(newItem);
    }
  });

  const listOfRemainingCountries = state.fullListOfCountries.filter(
    (country) => !selectedLocations.includes(country.iso),
  );

  listOfRemainingCountries.forEach((location) => {
    const newItem = generateObjectForGraph({
      state,
      location: location.iso,
      subject: selectedSubjects[0],
      selectedYearsRange1,
      selectedYearsRange2,
      subjectCustomColor: '30,30,30',
    });

    if (
      newItem.data[0].x !== notApplicable
      && newItem.data[0].y !== notApplicable
    ) {
      fullContextCollection.push(newItem);
    }
  });

  const listOfRemainingGroups = state.fullListOfCountryGroups.filter(
    (country) => !selectedLocations.includes(country.uid),
  );

  listOfRemainingGroups.forEach((location) => {
    const newItem = generateObjectForGraph({
      state,
      location: location.uid,
      subject: selectedSubjects[0],
      selectedYearsRange1,
      selectedYearsRange2,
      subjectCustomColor: '80,130,80',
    });

    if (
      newItem.data[0].x !== notApplicable
      && newItem.data[0].y !== notApplicable
    ) {
      fullContextCollection.push(newItem);
    }
  });

  const annotationLine = {
    id: 'annotationLine',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const { ctx } = chart;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.moveTo(activePoint.element.x, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = chart.tooltip.dataPoints[0].dataset.borderColor;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  const sideAnnotationLines = {
    id: 'sideAnnotationLines',
    afterDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      chart._metasets.forEach((point) => {
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left - 10, point.data[0].y);
        ctx.lineTo(chart.chartArea.left, point.data[0].y);
        ctx.moveTo(point.data[0].x, chart.chartArea.bottom + 10);
        ctx.lineTo(point.data[0].x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = point.data[0].options.borderColor;
        ctx.stroke();
        ctx.restore();
      });
    },
  };

  const data = {
    datasets: [...collection, ...fullContextCollection],
    maintainAspectRatio: false,
    labels: [
      ...collection.map((item) => item.label),
      ...fullContextCollection.map((item) => item.label),
    ],
  };

  return {
    type: 'scatter',
    data,
    plugins: [annotationLine, sideAnnotationLines],
    options: {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 35,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          min: 0,
          max: 100,
          grid: { display: false },
          title: {
            color: 'black',
            display: true,
            text: `${getSelectedSubjectName(state, selectedSubjects[0])}`,
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          ticks: {
            callback: (_, index, allTicks) => {
              const value = Number(allTicks[index].value);
              return value.toFixed(1);
            },
            step: 10,
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
            color: 'black',
            autoSkip: false,
          },
        },
        y: {
          min: 0,
          max: 500000,
          grid: { display: false },
          title: {
            display: true,
            text: 'GDP',
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          display: true,
          ticks: {
            callback: (_, index, allTicks) => {
              const value = Number(allTicks[index].value);
              return value;
            },
            step: 5000,
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
              color: 'black',
            },
          },
        },
      },
      interaction: {
        intersect: true,
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ScatterChartTooltip({
              state,
              context,
              selectedYears: {
                range1: {
                  from: selectedYearsRange1[0],
                  to: selectedYearsRange1[selectedYearsRange1.length - 1],
                },
                range2: {
                  from: selectedYearsRange2[0],
                  to: selectedYearsRange2[selectedYearsRange2.length - 1],
                },
              },
            });
          },
        },
      },
    },
  };
};
