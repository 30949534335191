// noinspection AllyPlainJsInspection

import '../../../../../legacyjs/custom/vars';
import { ScatterChartTooltip } from '../../../../components';
import {
  getSelectedLocations,
  getSelectedSubjects,
  getSelectedYears,
  getSubjectTitle,
  getLocationTitle,
  generateObjectForGraph,
  isCitvoiceMeasure,
} from '../../../../actions';
import i18n from "../../../../i18n";

const getChartScale = (data) => {
  let maxX = 0.05;
  let maxY = 0.05;

  data.datasets.forEach((value) => {
    const x = value.data['0'].x > 0 ? value.data['0'].x : value.data['0'].x * -1;
    const y = value.data['0'].y > 0 ? value.data['0'].y : value.data['0'].y * -1;

    if (x > maxX) maxX = Number(x);
    if (y > maxY) maxY = Number(y);
  });

  maxX *= 1.1;
  maxY *= 1.1;

  return {
    y: {
      min: Number.parseFloat(-Math.abs(maxY)).toFixed(3),
      max: Number.parseFloat(maxY).toFixed(3),
    },
    x: {
      min: Number.parseFloat(-Math.abs(maxX)).toFixed(3),
      max: Number.parseFloat(maxX).toFixed(3),
    },
  };
};

export const getChartTitle = (state) => {
  const chartTitle = i18n.t("{{subject}} for {{location}}", {
    subject: getSubjectTitle(state),
    location: getLocationTitle(state)
  });

  return chartTitle;
};

export default (state, urlParams) => {
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedYears2 = getSelectedYears('range2from', 'range2to');
  const selectedYearsRange1 = getSelectedYears('range1from', 'range1to'); // Copy to the specific object for this display
  const selectedYearsRange2 = getSelectedYears('range2from', 'range2to'); // Copy to the specific object for this display
  const selectedLocations = getSelectedLocations(state);
  const selectedSubjects = getSelectedSubjects(state);
  const collection = [];
  const fullContextCollection = [];
  const locationCount = selectedLocations.length;
  const notApplicable = 'n/a';

  // Multi-location
  if (locationCount > 1) {
    // For each selected location
    selectedLocations.forEach((location) => {
      const newItem = generateObjectForGraph({
        state,
        location,
        subject: selectedSubjects[0],
        selectedYearsRange1,
        selectedYearsRange2,
      });

      if (
        newItem.data[0].x !== notApplicable
        && newItem.data[0].y !== notApplicable
      ) {
        collection.push(newItem);
      }
    });

    if (JSON.parse(urlParams?.showFullContext || false)) {
      const listOfRemainingCountries = state.fullListOfCountries.filter(
        (country) => !selectedLocations.includes(country.iso),
      );

      listOfRemainingCountries.forEach((location) => {
        const newItem = generateObjectForGraph({
          state,
          location: location.iso,
          subject: selectedSubjects[0],
          selectedYearsRange1,
          selectedYearsRange2,
          subjectCustomColor: '52, 60, 66',// eslint-disable-line i18next/no-literal-string
          fullContext: true
        });

        if (
          newItem.data[0].x !== notApplicable
          && newItem.data[0].y !== notApplicable
        ) {
          fullContextCollection.push(newItem);
        }
      });

      const listOfRemainingGroups = state.fullListOfCountryGroups.filter(
        (country) => !selectedLocations.includes(country.uid),
      );

      listOfRemainingGroups.forEach((location) => {
        const newItem = generateObjectForGraph({
          state,
          location: location.uid,
          subject: selectedSubjects[0],
          selectedYearsRange1,
          selectedYearsRange2,
          subjectCustomColor: '52, 60, 66',// eslint-disable-line i18next/no-literal-string
          fullContext: true
        });

        if (
          newItem.data[0].x !== notApplicable
          && newItem.data[0].y !== notApplicable
        ) {
          fullContextCollection.push(newItem);
        }
      });
    }
  } else {
    // Single location, possibly many subjects
    selectedSubjects.forEach((subject) => {
      const newItem = generateObjectForGraph({
        state,
        location: selectedLocations[0],
        subject,
        selectedYearsRange1,
        selectedYearsRange2,
      });

      if (
        newItem.data[0].x !== notApplicable
        && newItem.data[0].y !== notApplicable
      ) {
        collection.push(newItem);
      }
    });

    if (JSON.parse(urlParams?.showFullContext || false)) {
      const listOfRemainingSubjects = state.fullListOfMeasures.filter(
        (subject) => !selectedLocations.includes(subject.s_id)
          && subject.lvl < 5
          && !isCitvoiceMeasure(state, subject.s_id),
      );

      listOfRemainingSubjects.forEach((subject) => {
        const newItem = generateObjectForGraph({
          state,
          location: selectedLocations[0],
          subject: subject.s_id,
          selectedYearsRange1,
          selectedYearsRange2,
          subjectCustomColor: '52, 60, 66',// eslint-disable-line i18next/no-literal-string
          fullContext: true
        });

        if (
          newItem.data[0].x !== notApplicable
          && newItem.data[0].y !== notApplicable
        ) {
          fullContextCollection.push(newItem);
        }
      });
    }
  }

  const annotationLine = {
    id: 'annotationLine',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const { ctx } = chart;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.moveTo(activePoint.element.x, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = chart.tooltip.dataPoints[0].dataset.borderColor;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  const sideAnnotationLines = {
    id: 'sideAnnotationLines',
    afterDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      chart._metasets.forEach((point) => {
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left - 10, point.data[0].y);
        ctx.lineTo(chart.chartArea.left, point.data[0].y);
        ctx.moveTo(point.data[0].x, chart.chartArea.bottom + 10);
        ctx.lineTo(point.data[0].x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = point.data[0].options.borderColor;
        ctx.stroke();
        ctx.restore();
      });
    },
  };

  const data = {
    datasets: [...collection, ...fullContextCollection],
    maintainAspectRatio: false,
    labels: [
      ...collection.map((item) => item.label),
      ...fullContextCollection.map((item) => item.label),
    ],
  };

  const chartScale = getChartScale(data);

  return {
    type: 'scatter',
    data,
    plugins: [annotationLine, sideAnnotationLines],
    options: {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 35,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          min: chartScale.x.min,
          max: chartScale.x.max,
          grid: {
            color(context) {
              if (context.tick.value === 0) {
                context.tick.value.toFixed(2);
                return '#000000';
              }
              return 'transparent';
            },
          },
          title: {
            color: 'black',
            display: true,
            text: `${i18n.t('AAT')}: ${selectedYears[0]}-${// eslint-disable-line i18next/no-literal-string
              selectedYears[selectedYears.length - 1]
            }`,
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          ticks: {
            callback: (val, index, allTicks) => {
              if (val === 0) {
                return Number(0).toFixed(2);
              }
              if (index === 0 || index === allTicks.length - 1) {
                const value = Number(allTicks[index].value);
                return value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2);
              }
              return '';
            },
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
            color: 'black',
            autoSkip: false,
          },
        },
        y: {
          min: chartScale.y.min,
          max: chartScale.y.max,
          grid: {
            color(context) {
              if (context.tick.value === 0) {
                return '#000000';
              }
              return 'transparent';
            },
          },
          title: {
            display: true,
            text: `${i18n.t('AAT')}: ${selectedYears2[0]}-${// eslint-disable-line i18next/no-literal-string
              selectedYears2[selectedYears2.length - 1]
            }`,
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
            },
          },
          display: true,
          ticks: {
            callback: (val, index, allTicks) => {
              if (val === 0) {
                return Number(0).toFixed(2);
              }
              if (index === 0 || index === allTicks.length - 1) {
                const value = Number(allTicks[index].value);
                return value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2);
              }
              return '';
            },
            color: 'black',
            font: {
              size: 16,
              family: 'museo-sans',
              weight: 500,
              color: 'black',
            },
          },
        },
      },
      interaction: {
        intersect: true,
      },
      animations: {
        x: {
          type: 'number',
          easing: 'easeInOutQuad',
          duration: 1000, // Adjust duration as needed
          from: (ctx) => {
            return (ctx.chart.chartArea.width / 2) + ctx.chart.chartArea.left;
          }
        },
        y: {
          type: 'number',
          easing: 'easeInOutQuad',
          duration: 1000, // Adjust duration as needed
        }
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ScatterChartTooltip({
              state,
              context,
              selectedYears: {
                range1: {
                  from: selectedYearsRange1[0],
                  to: selectedYearsRange1[selectedYearsRange1.length - 1],
                },
                range2: {
                  from: selectedYearsRange2[0],
                  to: selectedYearsRange2[selectedYearsRange2.length - 1],
                },
              },
            });
          },
        },
      },
    },
  };
};
